var Mainvisual = /** @class */ (function () {
    function Mainvisual() {
    }
    Mainvisual.prototype.start = function () {
        $('.main_visual .mv01').delay(250).queue(function () {
            $('.main_visual .mv01').addClass('show');
        });
        $('.main_visual .mv02').delay(500).queue(function () {
            $('.main_visual .mv02').addClass('show');
        });
        $('.main_visual .mv03').delay(750).queue(function () {
            $('.main_visual .mv03').addClass('show');
        });
        $('.main_visual .mv04').delay(1000).queue(function () {
            $('.main_visual .mv04').addClass('show');
        });
        setTimeout(function () {
            $('.news .scroll .sc_wrpr hr').toggleClass('anim');
        }, 3000);
    };
    return Mainvisual;
}());
export { Mainvisual };
