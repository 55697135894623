import { Common } from "../common/common";
var PopupMovie = /** @class */ (function () {
    function PopupMovie() {
        this.breakPoint = [641];
        this.common = new Common();
    }
    PopupMovie.prototype.show = function (id) {
        var _this = this;
        $('#popup_movie').addClass('active');
        $('#popup_movie .bg').stop(true, false).css({ height: '0%', display: 'block' }).animate({ height: '100%' }, 500, 'linear');
        $('#popup_movie .movie').stop(true, false).css({ opacity: 0 }).delay(500).animate({ opacity: 1 }, 500, 'linear');
        $('#popup_movie .btn_menu a, #popup_movie .bg').on('click.popup', function () {
            _this.close();
            (event.preventDefault) ? event.preventDefault() : event.returnValue = false;
            return false;
        });
        $('#popup_movie .movie .frame').empty().append('<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + id + '?rel=0&amp;controls=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>');
        $('html').css({ overflow: 'hidden' });
        this.resize(null);
        $(window).on('resize.popup', function (event) { _this.resize(event); });
    };
    PopupMovie.prototype.close = function () {
        var wid = this.common.getWid();
        $('#popup_movie .btn_menu a, #popup_movie .bg').off('.popup');
        $(window).off('.popup');
        $('#popup_movie .movie').stop(true, false).delay(150).animate({ opacity: 0 }, 500, 'linear');
        $('#popup_movie .bg').stop(true, false).delay(300).animate({ height: '0%' }, 500, 'easeOutExpo', function () {
            $('#popup_movie').removeClass('active').css({ display: '' });
            $('#popup_movie .bg').attr('style', '').css({ display: '' });
            $('#popup_movie .movie .frame').empty();
        });
        $('html').attr({ style: '' });
    };
    PopupMovie.prototype.resize = function (event) {
        var hig = $(window).innerHeight();
        var wid = $(window).width();
        var rasio = 720 / 1280;
        //let movWid:number = (wid*0.7 < 640)? 640 : wid*0.7;
        var movWid = wid * 0.7;
        var movHig = movWid * rasio;
        $('#popup_movie .movie .frame').css({
            width: movWid + 'px',
            height: movHig + 'px',
            left: -(movWid / 2) + 'px',
            top: -(movHig / 2) + 'px'
        });
    };
    return PopupMovie;
}());
export { PopupMovie };
