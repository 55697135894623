import { Mainvisual } from "./mainvisual";
import { PopupMovie } from './popupmovie';
var Top = /** @class */ (function () {
    function Top() {
        var scope = this;
        this.popupMovie = new PopupMovie();
        window.addEventListener('scroll', function () { scope.onScroll(); });
        window.addEventListener('resize', function () { scope.onResize(); });
        scope.onResize();
        scope.onScroll();
    }
    Top.prototype.load = function () {
        var scope = this;
        $('#faq .faq_inner .qa .q .btn a').on('click', function () {
            event.preventDefault();
            $(this).parent().parent().parent().toggleClass('open');
        });
        $('.faq .btn_more a').on('click', function (event) {
            event.preventDefault();
            if ($('.faq .btn_more').hasClass('open')) {
                $('.faq .btn_more').removeClass('open');
            }
            else {
                $('.faq .btn_more').addClass('open');
            }
            $('.faq .faq_inner2').slideToggle(250);
        });
        var mv = new Mainvisual();
        mv.start();
        $('#movie_thumb a').on('click', function () {
            event.preventDefault();
            scope.popupMovie.show('bsAOgWlir-Y');
        });
        // menu
        //pc
        $('.pc.g_menu a').on('click', function (event) {
            event.preventDefault();
            var crntTgt = $(event.currentTarget).attr('href');
            var hash = '#' + crntTgt.split('#')[1];
            if ($(hash).length > 0) {
                var posY = $(hash).offset().top;
                $("html,body").animate({ scrollTop: posY + 'px' }, 750);
            }
        });
        this.onResize();
    };
    Top.prototype.onResize = function () {
        var wid = window.innerWidth;
        // resize movie size
        var movWid = wid / 2 + 480;
        if (wid > 640) {
            $('#movie_thumb').width(movWid);
        }
        else {
            $('#movie_thumb').width('auto');
        }
    };
    Top.prototype.onScroll = function () {
        var scTop = $(window).scrollTop();
        var hig = $(window).innerHeight();
        var wid = $(window).innerWidth();
        var contsHig = $('body').innerHeight();
        var scTopH = (scTop + hig) - contsHig;
        if (wid > 640) {
            if (scTopH + 300 > 0) {
                $('.to_top').css({ marginBottom: (scTopH + 300) + 'px' });
            }
            else {
                $('.to_top').css({ marginBottom: '0px' });
            }
        }
        else {
            $('.to_top').css({ marginBottom: undefined });
        }
        $('.scrl, .scl').each(function (index, item) {
            var posY = $(item).offset().top;
            if (posY < scTop + (hig * 0.95) && !$(item).hasClass('show')) {
                $(item).addClass('show');
            }
        });
    };
    return Top;
}());
export { Top };
(function () {
    var top = new Top();
    window.addEventListener('load', function () {
        top.load();
    });
})();
