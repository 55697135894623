var Common = /** @class */ (function () {
    function Common() {
        this.isSp = false;
        //
        this.IS_FIREFOX = undefined;
        this.IE_VER = -1;
        this.IS_IE = false;
        console.log('common construct');
        this.init();
    }
    Common.prototype.load = function () {
        console.log('common load');
        this.setEvent();
    };
    Common.prototype.setEvent = function () {
        var scope = this;
        // sp
        $('.hdr .btn_menu a').on('click', function (event) {
            event.preventDefault();
            $('.hdr .menus').slideToggle();
        });
        $('.hdr .menus .btn_close a').on('click', function (event) {
            event.preventDefault();
            $('.hdr .menus').slideToggle();
        });
        $('.hdr .menus .g_menu a').on('click', function (event) {
            //event.preventDefault();
            $('.hdr .menus').slideUp(250);
            var crntTgt = $(event.currentTarget).attr('href');
            if ($(crntTgt).length > 0) {
                var posY = $(crntTgt).offset().top;
                setTimeout(function () {
                    $("html,body").animate({ scrollTop: posY + 'px' }, 750);
                }, 250);
            }
        });
        $('.to_top a').on('click', function (event) {
            event.preventDefault();
            console.log('click');
            $("html,body").animate({ scrollTop: '0px' }, 500);
        });
        window.addEventListener('scroll', function () { scope.onScroll(); });
        scope.onScroll();
    };
    Common.prototype.onScroll = function () {
        var scTop = $(window).scrollTop();
        var hig = $(window).innerHeight();
        if (scTop >= 100 && !$('.to_top').hasClass('show')) {
            $('.to_top').addClass('show');
        }
        if (scTop < 100 && $('.to_top').hasClass('show')) {
            $('.to_top').removeClass('show');
        }
    };
    Common.prototype.init = function () {
        this.setup();
        this.detectSp();
        this.detectBrowser();
    };
    ;
    Common.prototype.setup = function () {
        var hash = location.hash;
        if (hash === '#2019spring') {
            jQuery('body').addClass('spring');
        }
    };
    Common.prototype.detectSp = function () {
        if (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('iPad') > 0 ||
            navigator.userAgent.indexOf('iPod') > 0 ||
            navigator.userAgent.indexOf('Android') > 0 /* || navigator.userAgent.indexOf('iPad') > 0*/) {
            this.isSp = true;
        }
    };
    Common.prototype.getIsSp = function () {
        return this.isSp;
    };
    Common.prototype.scroll = function () {
        var hPos = $(window).scrollTop();
    };
    Common.prototype.getWid = function () {
        return (this.isSp) ? window.innerWidth : $(window).width();
    };
    Common.prototype.getHig = function () {
        return (this.isSp) ? window.innerHeight : $(window).height();
    };
    Common.prototype.getDocWid = function () {
        return $(document).width();
    };
    Common.prototype.getDocHig = function () {
        return $(document).height();
    };
    Common.prototype.encode = function (str) {
        return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;');
    };
    Common.prototype.detectBrowser = function () {
        var ua = navigator.userAgent.toLowerCase();
        var appVersion = navigator.appVersion.toLowerCase();
        if (ua.indexOf("firefox") != -1) {
            this.IS_FIREFOX = true;
        }
        else if (ua.indexOf("msie") != -1) {
            this.IS_IE = true;
            if (appVersion.indexOf("msie 6.") != -1) {
                this.IE_VER = 6;
            }
            else if (appVersion.indexOf("msie 7.") != -1) {
                this.IE_VER = 7;
            }
            else if (appVersion.indexOf("msie 8.") != -1) {
                this.IE_VER = 8;
            }
            else if (appVersion.indexOf("msie 9.") != -1) {
                this.IE_VER = 9;
            }
            else if (appVersion.indexOf("msie 10.") != -1) {
                this.IE_VER = 10;
            }
        }
        else if (ua.indexOf("windows") != -1 && ua.indexOf("chrome") == -1 && ua.indexOf("safari") == -1) {
            //IE 11
            this.IS_IE = true;
            var str = ua.substring(ua.indexOf('(') + 1, ua.indexOf(')'));
            var spStr = str.split(' ');
            var verN = 0;
            for (var i = 0; i < spStr.length; i++) {
                if (spStr[i].indexOf('rv:') != -1) {
                    verN = Number(spStr[i].replace('rv:', ''));
                    break;
                }
            }
            if (verN == 11) {
                this.IE_VER = 11;
            }
        }
    };
    return Common;
}());
export { Common };
(function () {
    var common = new Common();
    window.addEventListener('load', function () {
        common.load();
    });
})();
